<template>
  <v-container fill-height fluid ma-0 pa-0 :style="backgroundStyle">
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8" class="pa-0">
        <v-card
          class="mx-xs-0 pb-4 mx-auto px-2"
          :width="cardWidth"
        >
          <card-toolbar
            :disableSave="!valid"
            button-mode
            class="mx-2 my-0 pt-4"
            title="Reset Password"
          />
          <v-card-text class="pt-8">
            <v-form 
              v-model="valid"
              @submit.prevent
            >
              <v-row align="center" justify="center">
                <v-col
                  v-for="(field, i) in fields" :key="field.label"
                  cols="12"
                >
                  <v-text-field
                    v-model="field.value"
                    :append-icon="field.appendIcon"
                    :counter="field.counter"
                    :label="field.label"
                    :prepend-icon="field.prependIcon"
                    :rules="field.rules"
                    :type="field.type"
                    :error-messages="errorMessages"
                    @click:append="field.onAppendClick(i)"
                    @keypress.enter="valid && sendResetConfirmation()"
                    class="mx-1"
                    color="primary"
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mx-2">
            <v-btn
              block
              @click="sendResetConfirmation()"
              color="primary"
              :disabled="!valid"
              :loading="loading"
            >
              Reset Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, } from 'vuex'
import { transformError } from '../services/utility.service'

import { ICONS, ROUTES } from '../constants'
import { push } from '../router'
import AuthService from '../services/api/auth.service'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue')
  },
  data: () => ({
    initialized: false,
    loading: false,

    uid: null,
    token: null,
    valid: false,

    fields: [],
  }),
  computed: {
    ...mapGetters({
      backgroundStyle: 'sitemap/getBackgroundStyle',
    }),
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    cardWidth () {
      return this.isMobileSize ? '100%' : '400px'
    },
    errorMessages () {
      if (this.fields.length > 0 && this.fields[0].value && this.fields[1].value) {
        if (this.fields[0].value.toString() !== this.fields[1].value.toString()) {
          return ['Passwords must match.']
        }
      }
      return []
    },
    isMobileSize () {
      return this.breakpoint === 'xs'
    },
  },
  methods: {
    ...mapActions ({
      confirmPasswordReset: 'user/confirmPasswordReset',
      errorMessage: 'message/errorMessage',
      resetUser: 'user/resetUser',
      successMessage: 'message/successMessage',
      setBackground: 'sitemap/setBackground',
    }),
    initFields () {
      const ruleRequired = v => !!v || 'Value is required.'
      const rule128Length = v => v && v.toString().length <= 128 || 'Must have 128 characters or fewer.'
      const ruleTooSmall = v => v && v.toString().length >= 8 || 'Must have 8 characters or more.'
      const ruleNonNumeric = v => v && !(new RegExp('^[0-9]+$').test(v.toString())) || 'Must not only contain numeric characters.'

      this.fields = [
        { counter: 128, label: 'New Password', rules: [ruleRequired, rule128Length, ruleTooSmall, ruleNonNumeric], type: 'password', appendIcon: ICONS.SHOW, onAppendClick: this.showPasswordToggle, prependIcon: ICONS.LOCK, showPassword: false, value: null, },
        { counter: 128, label: 'Repeat New Password', rules: [ruleRequired, rule128Length, ruleTooSmall, ruleNonNumeric], type: 'password', appendIcon: ICONS.SHOW, onAppendClick: this.showPasswordToggle, prependIcon: ICONS.LOCK, showPassword: false, value: null, }
      ]
    },
    sendResetConfirmation () {
      this.loading = true

      this.confirmPasswordReset({
        uid: this.uid,
        token: this.token,
        new_password: this.fields[0].value,
        re_new_password: this.fields[1].value,
      })
        .then(async response => {
          this.successMessage('Password reset successful redirecting to sign-in page.')
          await new Promise(resolve => setTimeout(resolve, 1000));
          AuthService.logout()
          this.resetUser()
          push(ROUTES.SIGN_IN)
        })
        .catch(error => {
          if (error.status_code === 403) {
            error.message = 'Password reset URL has expired.'
          }
          this.errorMessage(`Failed to reset password. ${transformError(error)}`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showPasswordToggle (index) {
      this.fields[index].showPassword = !this.fields[index].showPassword
      this.fields[index].type = this.fields[index].showPassword ? 'text' : 'password'
      this.fields[index].appendIcon = this.fields[index].showPassword ? ICONS.HIDE : ICONS.SHOW
    },
  },
  async created() {
    this.uid = this.$route.params.uid,
    this.token = this.$route.params.token

    this.initFields()

    this.setBackground()

    this.initialized = true
  }
}
</script>
